<div>
  <mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav [fixedInViewport]="true" [autoFocus]="'first-heading'">
      <div class="sidenav-header">
        <h1 class="app-name">Material Theme</h1>
        <span class="spacer"></span>
        <button mat-icon-button aria-label="Close sidenav" (click)="sidenav.toggle()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <!--<app-menu></app-menu>-->
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar>
        <mat-toolbar-row>
          <button mat-icon-button class="header-button" (click)="openSidenav()">
            <mat-icon svgIcon="ak-menu"></mat-icon>
          </button>

          <h1 class="app-name">material theme</h1>
          <span class="example-spacer"></span>

          <button mat-icon-button class="header-button" [matMenuTriggerFor]="languageMenu">
            <mat-icon [svgIcon]="'ak-globe-web'" class="large-icon"></mat-icon>
          </button>

          <mat-menu #languageMenu="matMenu" class="header-menu">
            <button mat-menu-item>
              <mat-icon>translate</mat-icon>
              <span>language</span>
            </button>
            <button mat-menu-item>
              <mat-icon>access_time</mat-icon>
              <span>time zone</span>
            </button>
            <button mat-menu-item>
              <mat-icon>attach_money</mat-icon>
              <span>currency</span>
            </button>
          </mat-menu>

          <button mat-icon-button class="header-button" [matMenuTriggerFor]="personMenu">
            <mat-icon [svgIcon]="'ak-person'" class="large-icon"></mat-icon>
          </button>

          <mat-menu #personMenu="matMenu" class="header-menu">
            <button mat-menu-item>
              <mat-icon>logout</mat-icon>
              <span>logout</span>
            </button>
          </mat-menu>
          <button mat-icon-button class="header-button">
            <mat-icon [svgIcon]="'ak-search'" class="large-icon"></mat-icon>
          </button>
        </mat-toolbar-row>
      </mat-toolbar>
      <div class="item-wrapper">
        <section class="items">
          <div class="item full-width">
            <h2 class="top-aligned">usage</h2>
            <p>
              See documentation for
              <a href="https://github.com/Akelius/material-theme/blob/master/docs/README.md">getting started</a>,
              <a href="https://github.com/Akelius/material-theme/blob/master/docs/DETAIL.md"
                >detailed component usage</a
              >
              and
              <a href="https://github.com/Akelius/material-theme/blob/master/docs/HELPERS.md"
                >additional functionality</a
              >
            </p>

            <mat-form-field>
              <mat-select (valueChange)="setTenant($event)" [value]="tenant">
                <mat-option value="akelius">Akelius</mat-option>
                <mat-option value="material-theme-heimstaden">Heimstaden</mat-option>
                <mat-option value="material-theme-akelius-dark">Akelius dark (experimental - testing only)</mat-option>
                <mat-option value="akelius-legacy-theme">Akelius Legacy</mat-option>
              </mat-select>
              <mat-label>Tenant</mat-label>
            </mat-form-field>
          </div>

          <!-- typography -->

          <div class="item">
            <h2 class="top-aligned">typography</h2>
            <h1>h1 header</h1>
            <h2>h2 header</h2>
            <h3>h3 header</h3>
            <h3>
              <a
                href="/"
                [matTooltipShowDelay]="400"
                [matTooltipHideDelay]="4000"
                matTooltip="Info"
                [matTooltipPosition]="position.value"
                >h3 header link</a
              >
            </h3>
            <h4>h4 header</h4>

            <p>This is a paragraph containing a <a href="/">link</a> and a bit more text to fill it out.</p>

            <p>This is another paragraph.</p>

            <ol>
              <li>this is</li>
              <li>what an</li>
              <li>ordered list</li>
              <li>looks like</li>
            </ol>

            <ul>
              <li>looks like</li>
              <li>what an</li>
              <li>unordered list</li>
              <li>this is</li>
            </ul>
          </div>

          <!-- colors -->
          <div class="item">
            <h2 class="top-aligned">colors</h2>

            <section>
              <h3>branding</h3>

              <div class="colors">
                <div class="color">
                  <div class="box primary ripple-hover-overlay-after"></div>
                  <article>primary</article>
                </div>

                <div class="color">
                  <div class="box primary-text"></div>
                  <article>text on primary</article>
                </div>

                <div class="color">
                  <div class="box background ripple-hover-overlay-after"></div>
                  <article>background</article>
                </div>
              </div>

              <h3>base colors</h3>
              <div class="colors">
                <div class="color">
                  <div class="box standard-text"></div>
                  <article>standard text</article>
                </div>

                <div class="color">
                  <div class="box secondary-text"></div>
                  <article>secondary text</article>
                </div>

                <div class="color">
                  <div class="box disabled"></div>
                  <article>disabled</article>
                </div>

                <div class="color">
                  <div class="box line-border"></div>
                  <article>line/border</article>
                </div>

                <div class="color">
                  <div class="box ripple-color ripple-hover-overlay-after"></div>
                  <article>ripple color</article>
                </div>
              </div>

              <h3>semantic colors</h3>
              <div class="colors">
                <div class="color">
                  <div class="box success ripple-hover-overlay-after"></div>
                  <article>success</article>
                </div>

                <div class="color">
                  <div class="box warning ripple-hover-overlay-after"></div>
                  <article>warning</article>
                </div>

                <div class="color">
                  <div class="box error ripple-hover-overlay-after"></div>
                  <article>error</article>
                </div>

                <div class="color">
                  <div class="box info ripple-hover-overlay-after"></div>
                  <article>info</article>
                </div>
              </div>
            </section>
          </div>

          <!-- buttons -->
          <div class="item">
            <h2 class="top-aligned">buttons</h2>
            <section class="buttons">
              <button mat-flat-button color="primary">primary</button>
              <button mat-flat-button color="primary">
                <mat-icon [svgIcon]="'ak-person'"></mat-icon>primary - with icon
              </button>

              <button mat-flat-button>secondary</button>
              <button mat-flat-button><mat-icon [svgIcon]="'ak-person'"></mat-icon>secondary - with icon</button>

              <button mat-flat-button class="green">green</button>
              <button mat-flat-button class="green">
                <mat-icon [svgIcon]="'ak-person'"></mat-icon>green - with icon
              </button>

              <button mat-flat-button class="red">red</button>
              <button mat-flat-button class="red"><mat-icon [svgIcon]="'ak-person'"></mat-icon>red - with icon</button>

              <button mat-flat-button class="yellow">yellow</button>
              <button mat-flat-button class="yellow">
                <mat-icon [svgIcon]="'ak-person'"></mat-icon>yellow - with icon
              </button>

              <button mat-flat-button disabled color="primary">disabled</button>
              <button mat-flat-button disabled>
                <mat-icon [svgIcon]="'ak-person'"></mat-icon>disabled - with icon
              </button>

              <!--                for development testing only-->
              <!--                <button mat-flat-button disabled>secondary disabled</button>-->
              <!--                <button mat-flat-button disabled class="green">green disabled</button>-->
            </section>
            <h2>anchor</h2>
            <section class="buttons">
              <a href="https://www.akelius.com" target="_blank" mat-flat-button color="primary">link primary</a>
              <a mat-flat-button color="primary" href="https://www.akelius.com" target="_blank">
                <mat-icon [svgIcon]="'ak-person'"></mat-icon>primary with icon
              </a>

              <a href="https://www.akelius.com" target="_blank" mat-flat-button>link secondary</a>
              <a mat-flat-button href="https://www.akelius.com" target="_blank">
                <mat-icon [svgIcon]="'ak-person'"></mat-icon>secondary with icon
              </a>

              <a href="https://www.akelius.com" target="_blank" mat-flat-button class="green">link green</a>
              <a mat-flat-button href="https://www.akelius.com" target="_blank" class="green">
                <mat-icon [svgIcon]="'ak-person'"></mat-icon>green with icon
              </a>

              <a href="https://www.akelius.com" target="_blank" mat-flat-button class="red">link red</a>
              <a mat-flat-button href="https://www.akelius.com" target="_blank" class="red">
                <mat-icon [svgIcon]="'ak-person'"></mat-icon>red with icon
              </a>

              <a href="https://www.akelius.com" target="_blank" mat-flat-button class="yellow">link yellow</a>
              <a mat-flat-button href="https://www.akelius.com" target="_blank" class="yellow">
                <mat-icon [svgIcon]="'ak-person'"></mat-icon>yellow with icon
              </a>

              <a href="https://www.akelius.com" target="_blank" mat-flat-button disabled>link disabled</a>
              <a mat-flat-button disabled href="https://www.akelius.com" target="_blank">
                <mat-icon [svgIcon]="'ak-person'"></mat-icon>disabled with icon
              </a>
            </section>

            <h2>toggle</h2>

            <section>
              <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                <mat-button-toggle value="bold">Bold</mat-button-toggle>
                <mat-button-toggle value="italic">Italic</mat-button-toggle>
                <mat-button-toggle value="underline">Underline</mat-button-toggle>
              </mat-button-toggle-group>

              <h2>disabled group</h2>
              <mat-button-toggle-group disabled name="fontStyle" aria-label="Font Style">
                <mat-button-toggle value="bold">Bold</mat-button-toggle>
                <mat-button-toggle value="italic">Italic</mat-button-toggle>
                <mat-button-toggle value="underline">Underline</mat-button-toggle>
              </mat-button-toggle-group>

              <h2>disabled button</h2>
              <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                <mat-button-toggle value="bold">Bold</mat-button-toggle>
                <mat-button-toggle disabled value="italic">Italic</mat-button-toggle>
                <mat-button-toggle value="underline">Underline</mat-button-toggle>
              </mat-button-toggle-group>
            </section>
          </div>

          <!-- icons -->
          <div class="item">
            <h2 class="top-aligned">icons</h2>
            <section class="icons">
              <mat-icon>info</mat-icon>

              <button mat-icon-button>
                <mat-icon
                  [matTooltipShowDelay]="400"
                  [matTooltipHideDelay]="4000"
                  [matTooltipPosition]="position.value"
                  matTooltip="default mat-icon-button"
                  >info
                </mat-icon>
              </button>

              <button
                mat-icon-button
                class="icon-button-selected"
                matTooltip="Use class icon-button-selected to display selected state"
                [matTooltipPosition]="position.value"
                [matTooltipShowDelay]="400"
                [matTooltipHideDelay]="4000"
              >
                <mat-icon>info</mat-icon>
              </button>

              <button mat-icon-button disabled>
                <mat-icon>info</mat-icon>
              </button>
            </section>
            <h2>header buttons</h2>
            <section class="buttons">
              <button
                mat-icon-button
                class="header-button"
                matTooltip="standard header button style"
                [matTooltipShowDelay]="400"
                [matTooltipHideDelay]="4000"
                [matTooltipPosition]="position.value"
              >
                <mat-icon [svgIcon]="'ak-person'" class="large-icon"></mat-icon>
              </button>

              <button
                mat-icon-button
                class="header-button"
                aria-expanded="true"
                matTooltip="style shown automatically when an attached menu is open"
                [matTooltipShowDelay]="400"
                [matTooltipHideDelay]="4000"
                [matTooltipPosition]="position.value"
              >
                <mat-icon [svgIcon]="'ak-person'" class="large-icon"></mat-icon>
              </button>

              <button mat-icon-button class="header-button" disabled>
                <mat-icon [svgIcon]="'ak-person'" class="large-icon"></mat-icon>
              </button>
            </section>

            <h2>other</h2>
            <section class="buttons">
              <button
                mat-icon-button
                matTooltip="Use class top-button for to-top button only"
                [matTooltipShowDelay]="400"
                [matTooltipHideDelay]="4000"
                [matTooltipPosition]="position.value"
                class="top-button"
              >
                <mat-icon>arrow_upward</mat-icon>
              </button>
            </section>
          </div>

          <div class="item">
            <app-table></app-table>
          </div>

          <!-- progress indicator -->
          <div class="item">
            <h2 class="top-aligned">progress indicator</h2>
            <h3>spinner</h3>
            <div class="progress-container">
              <mat-spinner [diameter]="24" [strokeWidth]="4"></mat-spinner>
              <mat-spinner [diameter]="48" [strokeWidth]="8"></mat-spinner>
              <mat-spinner [diameter]="96" [strokeWidth]="12"></mat-spinner>
            </div>
            <h3>bar</h3>
            <mat-progress-bar value="66"></mat-progress-bar>
          </div>

          <!-- input using mat-label -->
          <div class="item">
            <h2 class="top-aligned">input using mat-label</h2>

            <mat-form-field>
              <input type="text" matInput autocomplete="off" required value="Året Österåker Berga" />
              <mat-label>Input 1</mat-label>
            </mat-form-field>

            <aside>
              <p>All mat-form-field elements can be used in default (56px high) or compact (48px) mode.</p>

              <p>
                To active compact mode, add the helper class <span class="code">.compact-form-fields</span> to any
                parent of the form field. Adding this class to the app component will set this globally.
              </p>
            </aside>
            <div class="compact-form-fields">
              <mat-form-field>
                <input type="text" matInput autocomplete="off" required value="Året Österåker Berga" />
                <mat-label>compact input</mat-label>
              </mat-form-field>
            </div>

            <aside>
              <p>By default, mat-form fields reserve a space underneath to allow for mat-hint/mat-error to appear</p>
              <p>To remove this default space, add the helper class .no-reserved-space</p>
            </aside>

            <mat-form-field class="no-reserved-space">
              <input type="text" matInput autocomplete="off" required value="Året Österåker Berga" />
              <mat-label>Input without reserved space</mat-label>
            </mat-form-field>

            <mat-form-field>
              <input type="text" disabled matInput value="Nope" autocomplete="off" />
              <mat-label>This input is disabled</mat-label>
            </mat-form-field>

            <mat-form-field>
              <input type="text" matInput placeholder="placeholder text" autocomplete="off" />
              <mat-label>This has a placeholder</mat-label>
            </mat-form-field>

            <mat-form-field>
              <input type="text" matInput autocomplete="off" required />
              <mat-label>Enter some text</mat-label>
            </mat-form-field>

            <mat-form-field>
              <input type="text" matInput autocomplete="off" required />
              <mat-label>Enter some text</mat-label>
              <mat-hint>A hint to completing the field. </mat-hint>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Input with validation</mat-label>
              <input type="text" required matInput autocomplete="off" [formControl]="errorInputForm1" />
              <mat-error>This field is required. </mat-error>
            </mat-form-field>

            <aside>
              <p>If you need multiline hints/errors, set the mat-form-field to use dynamic subscript sizing:</p>
              <p><span class="code">[subscriptSizing]="'dynamic'"</span></p>
              <p>This can cause layouts to shift and should be avoided if possible.</p>
            </aside>
            <mat-form-field [subscriptSizing]="'dynamic'">
              <input type="text" matInput autocomplete="off" required />
              <mat-label>Enter some text</mat-label>
              <mat-hint
                >A hint to completing the field. Hints can be multiline but should be kept as short as possible
              </mat-hint>
            </mat-form-field>

            <mat-form-field>
              <input type="text" matInput [matAutocomplete]="auto" />
              <mat-autocomplete #auto>
                <mat-option [value]="'first'">first</mat-option>
                <mat-option [value]="'second'">second</mat-option>
                <mat-option [value]="'third'">third</mat-option>
              </mat-autocomplete>
              <mat-label>Autocomplete example</mat-label>
            </mat-form-field>

            <app-input-clearable></app-input-clearable>

            <mat-form-field>
              <input matInput [(ngModel)]="searchValue" autocomplete="off" />
              <mat-icon matPrefix>search</mat-icon>
              <span *ngIf="searchValue !== ''" matIconSuffix>
                <button mat-icon-button (click)="searchValue = ''"><mat-icon>close</mat-icon></button></span
              >
              <mat-label>Search example</mat-label>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Textarea</mat-label>
              <textarea
                rows="7"
                matInput
                value="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat"
              ></textarea>
            </mat-form-field>
          </div>

          <!-- checkbox / radio button -->
          <div class="item">
            <section>
              <h2 class="top-aligned">checkbox</h2>
              <mat-checkbox class="example-checkbox">I am a checkbox</mat-checkbox>
              <mat-checkbox class="example-checkbox" checked="true">Another checkbox</mat-checkbox>
              <mat-checkbox class="example-checkbox" indeterminate="true">Indeterminate checkbox</mat-checkbox>
              <mat-checkbox class="example-checkbox"
                >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                ea rebum
              </mat-checkbox>
              <mat-checkbox class="example-checkbox" disabled checked="true">Disabled checked</mat-checkbox>
              <mat-checkbox class="example-checkbox" disabled>Disabled unchecked</mat-checkbox>
              <mat-checkbox class="example-checkbox" indeterminate="true" disabled>Disabled indeterminate</mat-checkbox>
            </section>
            <section>
              <h2>radio button</h2>
              <mat-radio-button class="example-radio" value="1" checked="true">Option 1</mat-radio-button>
              <mat-radio-button class="example-radio" value="2">Option 2</mat-radio-button>
              <mat-radio-button class="example-radio" value="3" disabled>Disabled option</mat-radio-button>
              <mat-radio-button class="example-radio" value="4" disabled checked="true"
                >Disabled checked option
              </mat-radio-button>
              <mat-radio-button class="example-radio"
                >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                ea rebum
              </mat-radio-button>
            </section>

            <section>
              <h2>list with selection</h2>
              <mat-selection-list>
                <mat-list-option checkboxPosition="before" [disableRipple]="true"> I am a checkbox </mat-list-option>
                <mat-list-option checkboxPosition="before" [disableRipple]="true"> Option 2 </mat-list-option>
                <mat-list-option checkboxPosition="before" [disableRipple]="true"> Option 3 </mat-list-option>
                <mat-list-option checkboxPosition="before" [disableRipple]="true"> Option 4 </mat-list-option>
              </mat-selection-list>
            </section>
            <section>
              <!-- slide toggle -->
              <h2 class="top-aligned">slide toggle</h2>
              <mat-slide-toggle [checked]="true">Enabled ON</mat-slide-toggle>
              <mat-slide-toggle [checked]="false">Enabled OFF</mat-slide-toggle>
              <br />
              <mat-slide-toggle [checked]="true" disabled>Disabled ON</mat-slide-toggle>
              <mat-slide-toggle [checked]="false" disabled>Disabled OFF</mat-slide-toggle>
            </section>
          </div>

          <!-- dropdown using mat-label -->
          <div class="item">
            <h2 class="top-aligned">dropdown using mat-label</h2>
            <mat-form-field>
              <mat-select>
                <mat-option value="1">Österåker Berga</mat-option>
                <mat-option value="2">option 2</mat-option>
                <mat-option value="3">option 3</mat-option>
                <mat-option value="4"
                  >option 4<br />
                  with multi-line text
                </mat-option>
              </mat-select>
              <mat-label>select an option</mat-label>
            </mat-form-field>

            <mat-form-field>
              <mat-select disabled>
                <mat-option value="1">Österåker Berga</mat-option>
                <mat-option value="2">option 2</mat-option>
                <mat-option value="3">option 3</mat-option>
                <mat-option value="4"
                  >option 4<br />
                  with multi-line text
                </mat-option>
              </mat-select>
              <mat-label>disabled dropdown</mat-label>
            </mat-form-field>

            <mat-form-field>
              <mat-select [formControl]="errorDropdownForm1">
                <mat-option value="1">Österåker Berga</mat-option>
                <mat-option value="2">option 2</mat-option>
                <mat-option value="3">option 3</mat-option>
                <mat-option value="4"
                  >option 4<br />
                  with multi-line text
                </mat-option>
              </mat-select>
              <mat-label>dropdown with validation</mat-label>
              <mat-error>This field is required.</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-select>
                <mat-optgroup label="numbers">
                  <mat-option value="1">1</mat-option>
                  <mat-option value="2">2</mat-option>
                  <mat-option value="2">3</mat-option>
                </mat-optgroup>
                <mat-optgroup label="letters">
                  <mat-option value="3">A</mat-option>
                  <mat-option value="4">B</mat-option>
                  <mat-option value="4">C</mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-label>dropdown with option groups</mat-label>
            </mat-form-field>

            <h2>dropdown using placeholder</h2>
            <mat-form-field>
              <mat-select required placeholder="Select an option">
                <mat-option value="1">Österåker Berga</mat-option>
                <mat-option value="2">option 2</mat-option>
                <mat-option disabled value="3">option 3</mat-option>
                <mat-option value="4"
                  >option 4<br />
                  with multi-line text
                </mat-option>
                <mat-option value="5">option 5</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-select disabled placeholder="disabled dropdown">
                <mat-option value="1">Österåker Berga</mat-option>
                <mat-option value="2">option 2</mat-option>
                <mat-option disabled value="3">option 3</mat-option>
                <mat-option value="4"
                  >option 4<br />
                  with multi-line text
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- dropdown with multiple selection -->
          <div class="item">
            <h2 class="top-aligned">dropdown with multiple selection</h2>
            <mat-form-field>
              <mat-select multiple>
                <mat-option value="1">Österåker Berga</mat-option>
                <mat-option value="2">multi-select option 2</mat-option>
                <mat-option disabled value="3">multi-select option 3</mat-option>
                <mat-option value="4">multi-select option 4<br />with multi-line text</mat-option>
              </mat-select>
              <mat-label>select an option</mat-label>
            </mat-form-field>

            <mat-form-field>
              <mat-select disabled multiple>
                <mat-option value="1">Österåker Berga</mat-option>
                <mat-option value="2">multi-select option 2</mat-option>
                <mat-option disabled value="3">multi-select option 3</mat-option>
                <mat-option value="4">multi-select option4 <br />with multi-line text</mat-option>
              </mat-select>
              <mat-label>disabled dropdown</mat-label>
            </mat-form-field>

            <mat-form-field>
              <mat-select [formControl]="errorDropdownForm1" multiple>
                <mat-option value="1">Österåker Berga</mat-option>
                <mat-option value="2">multi-select option 2</mat-option>
                <mat-option disabled value="3">multi-select option 3</mat-option>
                <mat-option value="4">multi-select option<br />with multi-line text</mat-option>
              </mat-select>
              <mat-label>dropdown with validation</mat-label>
            </mat-form-field>

            <mat-form-field>
              <mat-select multiple>
                <mat-optgroup label="numbers">
                  <mat-option value="1">1</mat-option>
                  <mat-option value="2">2</mat-option>
                  <mat-option value="2">3</mat-option>
                </mat-optgroup>
                <mat-optgroup label="letters">
                  <mat-option value="3">A</mat-option>
                  <mat-option value="4">B</mat-option>
                  <mat-option value="4">C</mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-label>dropdown with option groups</mat-label>
            </mat-form-field>
          </div>

          <!-- slider -->
          <div class="item">
            <h2 class="top-aligned">slider</h2>

            <h3>basic (mat-slider)</h3>
            <mat-slider min="0" max="100" value="60" color="primary">
              <input matSliderThumb />
            </mat-slider>
          </div>

          <!-- datepicker -->
          <div class="item">
            <h2 class="top-aligned">datepicker</h2>
            <mat-form-field>
              <input matInput [matDatepicker]="maxDatePicker" autocomplete="off" />
              <mat-datepicker-toggle matIconSuffix [for]="maxDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #maxDatePicker></mat-datepicker>
              <mat-label>select a date</mat-label>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled [matDatepicker]="maxDatePicker2" autocomplete="off" />
              <mat-datepicker-toggle matIconSuffix [for]="maxDatePicker2"></mat-datepicker-toggle>
              <mat-datepicker #maxDatePicker2></mat-datepicker>
              <mat-label>disabled datepicker</mat-label>
            </mat-form-field>

            <mat-form-field>
              <input matInput [formControl]="errorDatepickerForm" [matDatepicker]="maxDatePicker3" autocomplete="off" />
              <mat-datepicker-toggle matIconSuffix [for]="maxDatePicker3"></mat-datepicker-toggle>
              <mat-datepicker #maxDatePicker3></mat-datepicker>
              <mat-label>datepicker with validation</mat-label>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="rangePicker">
                <input matStartDate placeholder="Start date" />
                <input matEndDate placeholder="End date" />
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #rangePicker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <!-- chip/pill -->
          <div class="item">
            <h2 class="top-aligned">chips</h2>

            <aside>
              <p>
                Primary styles are triggered by <span class="code">color="primary"</span> or
                <span class="code">class="primary'</span>
              </p>
              <p>
                Colors can be triggered with color names or semantic style names e.g.
                <span class="code">class="green"</span>, <span class="code">class="warning"</span>, etc.
              </p>
            </aside>

            <mat-chip-set>
              <mat-chip>default</mat-chip>
              <mat-chip color="primary">primary</mat-chip>
              <mat-chip class="gray">info / gray</mat-chip>
              <mat-chip class="green">success / green</mat-chip>
              <mat-chip class="yellow">warning / yellow</mat-chip>
              <mat-chip class="red">error / red</mat-chip>
            </mat-chip-set>
            <h3 class="top-aligned">with icons</h3>

            <mat-chip-set>
              <mat-chip><mat-icon matChipAvatar [svgIcon]="'ak-person'"></mat-icon>default</mat-chip>
              <mat-chip color="primary"><mat-icon matChipAvatar [svgIcon]="'ak-person'"></mat-icon>primary</mat-chip>
              <mat-chip class="gray"><mat-icon matChipAvatar [svgIcon]="'ak-person'"></mat-icon>info / gray</mat-chip>
              <mat-chip class="green"
                ><mat-icon matChipAvatar [svgIcon]="'ak-person'"></mat-icon>success / green</mat-chip
              >
              <mat-chip class="yellow"
                ><mat-icon matChipAvatar [svgIcon]="'ak-person'"></mat-icon>warning / yellow</mat-chip
              >
              <mat-chip class="red"><mat-icon matChipAvatar [svgIcon]="'ak-person'"></mat-icon>error / red</mat-chip>
            </mat-chip-set>

            <h3 class="top-aligned">removable chips</h3>
            <mat-chip-row [editable]="true">
              test
              <button matChipRemove [attr.aria-label]="'remove test'">
                <mat-icon>close</mat-icon>
              </button>
            </mat-chip-row>
          </div>
          <!-- menu -->
          <div class="item">
            <h2 class="top-aligned">menu</h2>
            <section class="menu">
              <button mat-icon-button class="header-button" [matMenuTriggerFor]="menu">
                <mat-icon class="large-icon">person_outline</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item class="selected">
                  <mat-icon>home</mat-icon>
                  <span>home</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>person_outline</mat-icon>
                  <span>Sign out</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>power_settings_new</mat-icon>
                  <span>Shut down</span>
                </button>
                <button [disabled]="true" mat-menu-item>
                  <mat-icon>power_settings_new</mat-icon>
                  <span>Disabled</span>
                </button>
              </mat-menu>

              <button class="header-button" mat-icon-button [matMenuTriggerFor]="menu1">
                <mat-icon class="large-icon">alarm</mat-icon>
              </button>
              <mat-menu #menu1="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="childMenu">option without icon 1</button>
                <button mat-menu-item>option without icon 2</button>
                <button [disabled]="true" mat-menu-item>disabled option</button>
              </mat-menu>

              <mat-menu #childMenu="matMenu">
                <button mat-menu-item>Child 1</button>
                <button mat-menu-item>Child 2</button>
              </mat-menu>
            </section>
          </div>

          <!-- tabs -->
          <div class="item">
            <h2 class="top-aligned">tabs panel</h2>

            <h3>standard</h3>
            <mat-tab-group [animationDuration]="'0ms'">
              <mat-tab label="first">Tab 1 content</mat-tab>
              <mat-tab label="second">Tab 2 content</mat-tab>
              <mat-tab label="third">Tab 3 content</mat-tab>
              <mat-tab label="fourth">Tab 4 content</mat-tab>
              <mat-tab label="fifth"> Tab 5 content</mat-tab>
              <mat-tab label="disabled" disabled></mat-tab>
            </mat-tab-group>

            <h3>navigation based (tab links)</h3>
            <nav mat-tab-nav-bar [tabPanel]="tabPanel">
              <a
                mat-tab-link
                *ngFor="let link of tabLinks"
                (click)="$event.preventDefault(); activeTabLink = link"
                (keyup.enter)="$event.preventDefault(); activeTabLink = link"
                [active]="activeTabLink === link"
                href="#"
              >
                {{ link }}
              </a>
              <a mat-tab-link disabled>disabled</a>
            </nav>
            <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
          </div>

          <!-- expansion panel -->
          <div class="item">
            <h2 class="top-aligned">expansion panel</h2>
            <mat-expansion-panel [togglePosition]="'before'">
              <mat-expansion-panel-header>
                <mat-panel-title><h2>expand me (H2 header)</h2></mat-panel-title>
              </mat-expansion-panel-header>
              <article>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
              </article>
            </mat-expansion-panel>
            <mat-expansion-panel [togglePosition]="'before'">
              <mat-expansion-panel-header>
                <mat-panel-title
                  ><h3>
                    expand me (H3 header)<br />
                    with a break
                  </h3></mat-panel-title
                >
              </mat-expansion-panel-header>

              <article>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
              </article>
            </mat-expansion-panel>

            <mat-expansion-panel [togglePosition]="'before'" disabled>
              <mat-expansion-panel-header>
                <mat-panel-title><h3>disabled expansion</h3></mat-panel-title>
              </mat-expansion-panel-header>
            </mat-expansion-panel>

            <mat-expansion-panel [togglePosition]="'before'" disabled>
              <mat-expansion-panel-header>
                <mat-panel-title
                  ><h3>
                    disabled expansion <br />
                    with break
                  </h3></mat-panel-title
                >
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </div>

          <!-- sidenav -->
          <div class="item">
            <h2 class="top-aligned">sidenav</h2>
            <button mat-flat-button color="primary" (click)="openSidenav()">Open Sidenav</button>
          </div>

          <!-- snackbar -->
          <div class="item">
            <h2 class="top-aligned">snackbar</h2>
            <button mat-flat-button color="primary" (click)="openSnackbar()">Open snackbar</button>
          </div>

          <!-- dialog -->
          <div class="item">
            <h2 class="top-aligned">dialog</h2>

            <mat-form-field>
              <mat-label>dialog sizing</mat-label>
              <mat-select [formControl]="dialogForm">
                <mat-option value="">unconstrained - default</mat-option>
                <mat-option value="full-screen">full screen</mat-option>
                <mat-option value="constrained-dialog">constrained (legacy)</mat-option>
                <mat-option value="bottom-sheet">bottom sheet (testing)</mat-option>
              </mat-select>
            </mat-form-field>

            <button mat-flat-button color="primary" (click)="openDialog()">Open dialog</button>
          </div>

          <!-- tooltip -->
          <div class="item">
            <h2 class="top-aligned">tooltip</h2>

            <mat-form-field class="example-user-input">
              <mat-select placeholder="Allowed positions" [formControl]="position">
                <mat-option *ngFor="let positionOption of positionOptions" [value]="positionOption">
                  {{ positionOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <button
              mat-flat-button
              color="primary"
              [matTooltipShowDelay]="400"
              [matTooltipHideDelay]="4000"
              matTooltip="Info"
              [matTooltipPosition]="position.value"
              aria-label="Button that displays a tooltip in various positions"
            >
              Trigger
            </button>
          </div>
          <!-- stepper -->
          <app-stepper></app-stepper>

          <!-- card -->
          <div class="item full-width">
            <h2 class="top-aligned">cards</h2>
            <app-card-demo></app-card-demo>
          </div>
        </section>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
