/**
 * Module must be in CommonJS format
 */

const packageName = '@akelius/icon-library';

/**
 * Source folder within the package
 */
const sourceDir = 'icons';

/**
 * Icon names to copy
 */
const iconList = ['ak-info-filled', 'ak-globe-web', 'ak-person', 'ak-search', 'ak-close', 'ak-menu'];

/**
 * Destination folder relative to your project root
 */
const destDir = './src/assets/icons';

module.exports = {
  packageName,
  sourceDir,
  iconList,
  destDir,
};
