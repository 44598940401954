<div class="item">
  <h2 class="top-aligned">horizontal stepper linear</h2>
  <mat-horizontal-stepper [linear]="true" #stepper [disableRipple]="true">
    <ng-template matStepperIcon="edit" let-index="index"> {{ index + 1 }} </ng-template>
    <mat-step>
      <ng-template matStepLabel>
        <div>First step</div>
        <div>sub label</div>
      </ng-template>
      <p>
        We will start the wizard
      </p>
      <div class="actions">
        <button mat-flat-button matStepperNext color="primary">next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>
          <div>Fill out your address</div>
          <div>sub label</div>
        </ng-template>
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required />
        </mat-form-field>
        <div class="actions">
          <button mat-flat-button matStepperPrevious>Back</button>
          <button mat-flat-button matStepperNext color="primary">next</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        <div>Done</div>
        <div>sub label</div>
      </ng-template>
      <p>You are now done.</p>
      <div class="actions">
        <button mat-flat-button matStepperPrevious>Back</button>
        <button mat-flat-button (click)="stepper.reset()">Reset</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div class="item">
  <h2 class="top-aligned">vertical stepper linear</h2>
  <mat-vertical-stepper [linear]="true" #stepper>
    <ng-template matStepperIcon="edit" let-index="index"> {{ index + 1 }} </ng-template>
    <mat-step>
      <ng-template matStepLabel>
        <div>First step</div>
        <div>sub label</div>
      </ng-template>
      <p>
        We will start the wizard
      </p>
      <div>
        <button mat-flat-button matStepperNext color="primary">next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>
          <div>Fill out your address</div>
          <div>sub label</div>
        </ng-template>
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required />
        </mat-form-field>
        <div>
          <button mat-flat-button matStepperPrevious>Back</button>
          <button mat-flat-button matStepperNext color="primary">next</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        <div>Done</div>
        <div>sub label</div>
      </ng-template>
      <p>You are now done.</p>
      <div>
        <button mat-flat-button matStepperPrevious>Back</button>
        <button mat-flat-button (click)="stepper.reset()">Reset</button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>

<div class="item">
  <h2 class="top-aligned">horizontal stepper not linear</h2>
  <mat-horizontal-stepper [linear]="false" #stepper [disableRipple]="true">
    <ng-template matStepperIcon="edit" let-index="index"> {{ index + 1 }} </ng-template>
    <mat-step>
      <ng-template matStepLabel>First step</ng-template>
      <p>
        We will start the wizard
      </p>
      <div class="actions">
        <button mat-flat-button matStepperNext color="primary">next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Fill out your address</ng-template>
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required />
        </mat-form-field>
        <div class="actions">
          <button mat-flat-button matStepperPrevious>Back</button>
          <button mat-flat-button matStepperNext color="primary">next</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <p>You are now done.</p>
      <div class="actions">
        <button mat-flat-button matStepperPrevious>Back</button>
        <button mat-flat-button (click)="stepper.reset()">Reset</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div class="item">
  <h2 class="top-aligned">horizontal stepper linear with step labels on bottom</h2>
  <mat-horizontal-stepper [linear]="true" #stepper [disableRipple]="true" labelPosition="bottom">
    <ng-template matStepperIcon="edit" let-index="index"> {{ index + 1 }} </ng-template>
    <mat-step>
      <ng-template matStepLabel>First step</ng-template>
      <p>
        We will start the wizard
      </p>
      <div class="actions">
        <button mat-flat-button matStepperNext color="primary">next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Fill out your address</ng-template>
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required />
        </mat-form-field>
        <div class="actions">
          <button mat-flat-button matStepperPrevious>Back</button>
          <button mat-flat-button matStepperNext color="primary">next</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <p>You are now done.</p>
      <div class="actions">
        <button mat-flat-button matStepperPrevious>Back</button>
        <button mat-flat-button (click)="stepper.reset()">Reset</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
