import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { iconList } from '../../../icons.config';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private iconList: string[];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.iconList = iconList;
  }

  get icons(): string[] {
    return this.iconList;
  }

  hasIcon(icon: string): boolean {
    return this.icons.includes(icon) || false;
  }

  registerIcons(): void {
    this.iconList.forEach((icon) => {
      const iconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${icon}.svg`);
      this.iconRegistry.addSvgIcon(icon, iconUrl);
    });
  }

  filter(value: string): string[] {
    return this.icons.filter((icon) => icon.toLowerCase().includes(value.toLocaleLowerCase()));
  }
}
