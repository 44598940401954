import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { ModalContentComponent } from 'src/modal-content/modal-content.component';
import { BottomSheetContentComponent } from './bottom-sheet-content/bottom-sheet-content.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CommonModule } from '@angular/common';
import { CardDemoComponent } from './components/card-demo/card-demo.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { InputClearableComponent } from './components/input-clearable/input-clearable.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { TableComponent } from './components/table/table.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CardDemoComponent,
    StepperComponent,
    MatTooltipModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatChipsModule,
    MatDatepickerModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatListModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    InputClearableComponent,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    TableComponent,
    MatButtonToggleModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
  ],
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav') private sidenavComponent: MatSidenav;

  tenant = '';

  errorInputForm1 = new UntypedFormControl(null, Validators.required);

  errorDropdownForm1 = new UntypedFormControl(null, Validators.required);
  errorDropdownForm2 = new UntypedFormControl(null, Validators.required);
  errorDatepickerForm = new UntypedFormControl(null, Validators.required);

  positionOptions: TooltipPosition[] = ['above', 'below', 'left', 'right'];

  dialogForm = new UntypedFormControl('');

  position = new UntypedFormControl(this.positionOptions[0]);
  searchValue = 'example search text';

  tabLinks = ['first', 'second', 'third', 'fourth', 'fifth'];
  activeTabLink = 'first';

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private bottomSheet: MatBottomSheet,
  ) {}

  ngOnInit(): void {
    this.errorInputForm1.markAsTouched();
    this.errorDropdownForm1.markAllAsTouched();
    this.errorDropdownForm2.markAllAsTouched();
    this.errorDatepickerForm.markAllAsTouched();
  }

  openSnackbar(): void {
    this.snackBar.open('I am a snackbar', 'Click me!', {
      duration: 3000,
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
    });
  }

  openDialog(): void {
    if (this.dialogForm.value === 'bottom-sheet') {
      this.bottomSheet.open(BottomSheetContentComponent);
    } else {
      this.dialog.open(ModalContentComponent, {
        panelClass: this.dialogForm.value,
        autoFocus: 'first-heading',
      });
    }
  }

  openSidenav(): void {
    this.sidenavComponent.open();
  }

  setTenant(tenant: string) {
    if (this.tenant) {
      this.renderer.removeClass(document.body, this.tenant);
    }
    this.tenant = tenant;
    this.renderer.addClass(document.body, this.tenant);
  }
}
