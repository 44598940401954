<div mat-dialog-title>
  <h2>Lorem ipsum</h2>
  <button mat-icon-button mat-dialog-close [disableRipple]="true">
    <mat-icon [svgIcon]="'ak-close'"></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <section>
    <mat-form-field>
      <mat-label>Outline form field</mat-label>
      <input matInput placeholder="Placeholder" />
    </mat-form-field>
  </section>
  <section>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
    <p>
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
      aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo
      enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui
      ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
      adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
      voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut
      aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
      molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
    </p>

    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
  </section>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close class="red">delete</button>
  <button mat-flat-button mat-dialog-close>cancel</button>
  <a href="/" mat-flat-button mat-dialog-close>hyperlink</a>
  <!--
Example of a custom component containing a button.
     The component manually removes the margin from the button element
     and adds dialog-action-item to the wrapping div element to give the correct margin.
-->
  <div class="wrapped-button dialog-action-item">
    <button mat-flat-button>custom</button>
  </div>
  <button mat-flat-button mat-dialog-close color="primary">okay</button>
</mat-dialog-actions>
