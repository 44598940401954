<h2 class="top-aligned">table</h2>
<table mat-table [dataSource]="dataSource" matSort>
  <!-- Position Column -->
  <ng-container matColumnDef="position" sticky="true">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
    <td mat-cell *matCellDef="let element">{{ element.position }}</td>
    <td mat-footer-cell *matFooterCellDef>Average</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let element">
      <a href="#">{{ element.name }}</a>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="weight">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-right">Weight<br />(atomic)</th>
    <td mat-cell *matCellDef="let element" class="column-right">{{ element.weight }}</td>
    <td mat-footer-cell *matFooterCellDef class="column-right"></td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="symbol">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Symbol</th>
    <td mat-cell *matCellDef="let element">{{ element.symbol }}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="favourite" stickyEnd="true">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="button-cell">
      <button mat-icon-button>
        <mat-icon>favorite_outline</mat-icon>
      </button>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
